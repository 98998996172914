import service from '../service.js';

// 1.1 获取token令牌
export function getToken(params){
    return service({
        url: '/token',
        method: 'post',
        data: params
    })
}
// 1.2 注册会员信息
export function subRegister(params){
    return service({
        url: '/member/register',
        method: 'post',
        data: params
    })
}
// 1.3 获取会员收藏新闻列表
export function getCollectList(params){
    return service({
        url: '/member/collection',
        method: 'post',
        data: params
    })
}
// 1.4 会员添加新闻收藏 {news_id}
export function subCollect(params){
    return service({
        url: '/member/collection/save',
        method: 'post',
        data: params
    })
}
// 1.5 会员取消新闻收藏 {news_id}
export function removeCollect(params){
    return service({
        url: '/member/collection/delete',
        method: 'post',
        data: params
    })
}
// 1.6 会员信息编辑
export function updateMember(params){
    return service({
        url: '/member/update',
        method: 'post',
        data: params
    })
}


//新接口-----------------------------------------------------------------
// 获取新闻模块列表
export function getNewsModel(params){
    return service({
        url: '/newsmodel',
        method: 'post',
        data: params
    })
}
//新闻模块内容详情
export function getNewsmodelDetail(params){
    return service({
        url: '/newsmodel/read',
        method: 'post',
        data: params
    })
}
// 首页轮播图内容推荐
export function getHomeInfo(params){
    return service({
        url: '/homepage/information',
        method: 'get',
        params: params
    })
}
// 首页轮播图内容推荐
export function getHomeRec(params){
    return service({
        url: '/homepage/recommend',
        method: 'get',
        params: params
    })
}
// 3.4 新闻模块热度推荐列表  新闻页面右侧推荐
export function getNewsmodelHeat(params){
    return service({
        url: '/newsmodel/heat',
        method: 'post',
        data: params
    })
}


//新接口 end -----------------------------------------------------------



// 2.1 品牌分类获取
export function getBrandCategory(params){
    return service({
        url: '/brand/category',
        method: 'get',
        params: params
    })
}
// 2.2 品牌列表获取
export function getBrand(params){
    // console.log(params)
    return service({
        url: '/brand',
        method: 'get',
        params: params
    })
}
// 2.5 获取品牌详情
export function getBrandDetail(params){
    return service({
        url: '/brand/read',
        method: 'post',
        data: params
    })
}

// 3.1 新品分类获取
export function getNewsCategory(params){
    return service({
        url: '/news/category',
        method: 'get',
        params: params
    })
}
// 3.2 新品列表获取
export function getNews(params){
    // console.log(params)
    return service({
        url: '/news',
        method: 'get',
        params: params
    })
}
// 3.3 推荐新品列表获取
export function getNewsRecommend(params){
    return service({
        url: '/news/recommend',
        method: 'get',
        params: params
    })
}
// 3.4 首页新品资讯
// export function getNewsInformation(params){
//     return service({
//         url: '/news/information',
//         method: 'get',
//         params: params
//     })
// }
// 3.5 获取新品详情{news_id}
export function getNewsDetail(params){
    return service({
        url: '/news/read',
        method: 'post',
        data: params
    })
}



// 4.1 技术分类获取
// export function getTechnologyCategory(params){
//     return service({
//         url: '/technology/category',
//         method: 'get',
//         params: params
//     })
// }
// // 4.2 技术列表获取
// export function getTechnology(params){
//     // console.log(params)
//     return service({
//         url: '/technology',
//         method: 'get',
//         params: params
//     })
// }
// // 2.5 获取品牌详情
// export function getTechnologyDetail(params){
//     return service({
//         url: '/technology/read',
//         method: 'post',
//         data: params
//     })
// }

// // 4.1  招聘分类获取
// export function getRecruiterCategory(params){
//     return service({
//         url: '/recruiter/category',
//         method: 'get',
//         params: params
//     })
// }
// // 4.2  招聘列表获取
// export function getRecruiter(params){
//     // console.log(params)
//     return service({
//         url: '/recruiter',
//         method: 'get',
//         params: params
//     })
// }
// // 2.5  招聘品牌详情
// export function getRecruiterDetail(params){
//     return service({
//         url: '/recruiter/read',
//         method: 'post',
//         data: params
//     })
// }



// 4.1 展会相册获取
export function getExpoAlbum(params){
    return service({
        url: '/expo/album',
        method: 'get',
        params: params
    })
}

// 5.1 全局搜索
export function getSearch(params){
    return service({
        url: '/search',
        method: 'get',
        params: params
    })
}

// 6.1 提交留言信息
export function subMessage(params){
    return service({
        url: '/leave/word/save',
        method: 'post',
        data: params
    })
}

// 新闻模块关键词列表 newsmodel/keywords
export function getKeywords(params){
    return service({
        url: '/newsmodel/keywords',
        method: 'get',
        params: params
    })
}