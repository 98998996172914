import './LoginBox.css';
import React,{useState,useCallback,useEffect} from 'react';
import icon_close from '@/assets/icon_close.png';
import { Toast } from 'antd-mobile'

import { useSelector, useDispatch } from 'react-redux'
import { closeLoginBox,setToken } from '@/stores/userSlice'
import {getToken} from '@/api/'
// import  IconSearch from '@/components/icons/IconSearch.jsx'

import { useNavigate } from "react-router-dom";


export default function DHeader(props){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginBox = useSelector((state) => state.user.loginBox);

    const [formData,setFormData] = useState({
        email: localStorage.getItem("email")||'',
        password:''
    })

    const inputChange = useCallback((e)=>{
        const data = { ...formData }
        data[e.target.name] = e.target.value;
        setFormData(data);
     },[formData]);

    const sub = useCallback(()=>{
        console.log(formData)
        if(formData.email===''){
            Toast.show({content: 'The email field is required.'})
            return false;
        }
        if(formData.password===''){
            Toast.show({content: 'The name field is required.'})
            return false;
        }

        getToken(formData).then(res=>{
            console.log(res)
            if(res.errcode===200){
                // localStorage.setItem("token",res.data.token)
                dispatch(setToken(res.data))
                dispatch(closeLoginBox())
                Toast.show({content: 'Success!'})
            }else{
                Toast.show({content: res.errmsg})
            }
        })
    },[formData,dispatch])

    const toRegister = useCallback(()=>{
        dispatch(closeLoginBox());
        navigate("/register",{replace:true})
    },[navigate,dispatch])


	return (
		<div className={loginBox?'loginBox':'loginBox hide'}>
            <div className='maskbg'></div>
            <div className='close' title='close' onClick={()=>dispatch(closeLoginBox())}><img src={icon_close} alt='close' /></div>
            <div className='wrap'>
                <div className='center'>
                    <h2>Login</h2>
                    <div className='flexbox'>
                        <div>
                            <input type='text' placeholder='Email' name="email" value={formData.email} onChange={inputChange} />
                            <input type='password' placeholder='Password' name="password" value={formData.password} onChange={inputChange} />
                            <button onClick={(e)=>sub(e)}>Log in</button>
                            {/* <span className='a findpass'>Forgot Your Password</span> */}
                        </div>
                        <div>
                            <h2>New To Us?</h2>
                            <span onClick={toRegister} className='a'>Click to Restricted</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	);
};