import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getCollectList} from '@/api/index.js'

const initialState = {
  loginBox:false,
  token:localStorage.getItem('token')||'',
  username:'',
  user:JSON.parse(localStorage.getItem('user')||'{}'),
  collect:{}
}

//获取新闻分类
export const getCollect = createAsyncThunk('user/getCollect', async (params={}) => {
  const payload = await getCollectList(Object.assign({token:initialState.token}, params))
  return payload
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    openLoginBox:(state)=>{
      state.loginBox = true;
    },
    closeLoginBox:(state)=>{
      state.loginBox = false;
    },
    setToken:(state, action) => {
      localStorage.setItem('token',action.payload.token);
      localStorage.setItem('user',JSON.stringify(action.payload));
      state.token = action.payload.token;
      state.user = action.payload;
    },
    setUser:(state, action) => {
      console.log("userSlice setUser",action.payload)
      localStorage.setItem('user',JSON.stringify(action.payload));
      state.user = action.payload
    },
    setCollect:(state, action) => {
      state.collect = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCollect.fulfilled, (state, { payload }) => {
      console.log(payload)
      payload.errcode===200&&(state.collect = payload.data);
      // state.total = payload.total;
    })
  }
})

// Action creators are generated for each case reducer function
export const { setUser,setCollect,setToken,openLoginBox,closeLoginBox } = userSlice.actions

export default userSlice.reducer