import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getKeywords} from '@/api/index.js'


const initialState = {
  value: 0,
  keywordList:[]
}

//获取搜索关键词
export const getKeywordsThunk = createAsyncThunk('user/getCollect', async (params={}) => {
  const payload = await getKeywords(Object.assign({token:initialState.token}, params))
  return payload
})

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getKeywordsThunk.fulfilled, (state, { payload }) => {
      console.log(payload)
      payload.errcode===200&&(state.keywordList = payload.data);
      // state.total = payload.total;
    })
  }
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default counterSlice.reducer