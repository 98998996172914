import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getNewsCategory,getNewsModel,getHomeRec,getHomeInfo,getNewsmodelHeat} from '@/api/index.js'


const initialState = {
   typeArr:[],
   recommendList:[], //轮播图数据
   indexData:{}, //首页推荐数据
   industryList:{},
   productList:{},
   brandList:{},
   technologyList:{},
   recruiterList:{},
   heatList:[]
}

//获取新闻分类
export const getNewsType = createAsyncThunk('news/getType', async (param) => {
   const payload = await getNewsCategory()
   return payload
})
// // 获取首页新闻资讯 首屏4条新闻
// export const getNewsInfo = createAsyncThunk('news/getInfo', async (param) => {
//     const payload = await getNewsInformation()
//     return payload
// })
// // 获取首页视频第一页
// export const getIndexVideo = createAsyncThunk('news/getIndexVideo', async (param) => {
//     const payload = await getVideo({page:1,number:10})
//     return payload
// })
// // 获取视频列表
// export const getVideoList = createAsyncThunk('news/getVideoList', async (param) => {
//     console.log(param)
//     const payload = await getVideo(Object.assign({number:10},param))
//     return payload
// })

//获取首页数据
export const getIndexData = createAsyncThunk('getIndexData', async (param) => {
    console.log(param)
    const payload = await getHomeInfo(param)
    return payload
 })
// 获取首页轮播图
export const getNewsRec = createAsyncThunk('news/getRec', async (param) => {
    const payload = await getHomeRec()
    return payload
})
// 获取行业列表
export const getIndustryList = createAsyncThunk('news/getIndustryList', async (param) => {
    console.log(param)
    const payload = await getNewsModel(Object.assign({number:10, table_name:'industry'},param))
    return payload
 })
// 获取产品列表
export const getProductList = createAsyncThunk('news/getProductList', async (param) => {
   console.log(param)
   const payload = await getNewsModel(Object.assign({number:10, table_name:'product'},param))
   return payload
})
// 获取品牌列表
export const getBrandList = createAsyncThunk('news/getBrandList', async (param) => {
    console.log(param)
    const payload = await getNewsModel(Object.assign({number:10,table_name:'brand'},param))
    return payload
})
//获取技术列表
export const getTechnologyList = createAsyncThunk('news/getTechnologyList', async (param) => {
    console.log(param)
    const payload = await getNewsModel(Object.assign({number:10,table_name:'technology'},param))
    return payload
})
//获取招聘列表
export const getRecruiterList = createAsyncThunk('news/getRecruiterList', async (param) => {
   console.log(param)
   const payload = await getNewsModel(Object.assign({number:10,table_name:'recruiter'},param))
   return payload
})

// 新闻模块热度推荐列表  新闻板块右侧推荐
export const getNewsHeat = createAsyncThunk('news/getNewsmodelHeat', async (param) => {
    console.log(param)
    const payload = await getNewsmodelHeat(Object.assign({table_name:'industry'},param))
    return payload
 })


export const newSlice = createSlice({
  name: 'new',
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder.addCase(getNewsType.fulfilled, (state, { payload }) => {
            // console.log(payload)
            payload.errcode===200&&(state.typeArr = payload.data);
            // state.total = payload.total;
        })
        .addCase(getNewsRec.fulfilled, (state, { payload }) => {
            // console.log(payload)
            payload.errcode===200&&(state.recommendList = payload.data);
        })
        // .addCase(getNewsInfo.fulfilled, (state, { payload }) => {
        //     // console.log(payload)
        //     payload.errcode===200&&(state.informationList = payload.data);
        // })
        // .addCase(getIndexExpo.fulfilled, (state, { payload }) => {
        //     // console.log(payload)
        //     if(payload.errcode===200){
        //         state.indexExpoList = payload.data.news_list;
        //         //首页获取展会新闻第一页，如果此时展会页面没数据，可以填充给展会列表
        //         if(JSON.stringify(state.expoList) === "{}"){
        //             state.expoList = payload.data;
        //         }
        //     }
        // })
        //   .addCase(getIndexVideo.fulfilled, (state, { payload }) => {
        //       // console.log(payload)
        //       if(payload.errcode===200){
        //           state.indexVideoList = payload.data.video_list;
        //            //首页获取视频新闻第一页，如果此时视频页面没数据，可以填充给视频页面
        //           if(JSON.stringify(state.videoList) === "{}"){
        //               state.videoList = payload.data;
        //           }
        //       }
        //   })
        //   .addCase(getVideoList.fulfilled, (state, { payload }) => {
        //       // console.log(payload)
        //       payload.errcode===200&&(state.videoList = payload.data);
        //   })
        
        .addCase(getIndexData.fulfilled, (state, { payload }) => {
            console.log(payload)
            payload.errcode===200&&(state.indexData = payload.data);
        })
        .addCase(getIndustryList.fulfilled, (state, { payload }) => {
            payload.errcode===200&&(state.industryList = payload.data);
        })
        .addCase(getProductList.fulfilled, (state, { payload }) => {
            // console.log(payload)
            payload.errcode===200&&(state.productList = payload.data);
        })
        .addCase(getBrandList.fulfilled, (state, { payload }) => {
            // console.log(payload)
            payload.errcode===200&&(state.brandList = payload.data);
        })
        .addCase(getTechnologyList.fulfilled, (state, { payload }) => {
            // console.log(payload)
            payload.errcode===200&&(state.technologyList = payload.data);
        })
        .addCase(getRecruiterList.fulfilled, (state, { payload }) => {
         // console.log(payload)
         payload.errcode===200&&(state.recruiterList = payload.data);
        })
        .addCase(getNewsHeat.fulfilled, (state, { payload }) => {
            // console.log(payload)
            payload.errcode===200&&(state.heatList = payload.data);
        })
    }
})

// Action creators are generated for each case reducer function
export const { setUser,setCollect,setToken,openLoginBox,closeLoginBox } = newSlice.actions

export default newSlice.reducer