import './DFooter.css';

const DFooter = (props) => {

	return (
		<footer>
			<div className="wrap">
				<div>
					<a href='###'>Terms</a>
					<a href='###'>Advertisement</a>
					<a href='###'>Enterprise Service</a>
				</div>
				<div className="mobile-hide">
					Copyright © 2015 - All Rights Reserved - CeramicTownWeekly - <a href="https://beian.miit.gov.cn">粤ICP备13055433号</a>
				</div>
				<div className="pc-hide">
					CeramicTownWeekly - <a href="https://beian.miit.gov.cn">粤ICP备13055433号</a>
				</div>
			</div>
		</footer>
	);
};

export default DFooter;