const routes = [
    {
        path: '/',
        component: () => import('@/layouts/base.jsx'),
        children: [
            {
                path: '/',
                redirect: '/index',
            },
            {
                path: '/index',
                component: () => import('@/views/index'),
                meta: {
                    title: "陶城报官网陶城网",
                    needLogin:false
                }
            },
            {
                path: '/industry',
                component: () => import('@/views/industry'),
                meta: {
                    title: "陶城报官网陶城网-陶瓷卫浴行业新闻",
                }
            },
            {
                path: '/brand',
                component: () => import('@/views/brand'),
                meta: {
                    title: "陶城报官网陶城网-陶瓷卫浴品牌",
                }
            },
            {
                path: '/product',
                component: () => import('@/views/product'),
                meta: {
                    title: "陶城报官网陶城网-陶瓷卫浴产品",
                }
            },
            {
                path: '/technology',
                component: () => import('@/views/technology'),
                meta: {
                    title: "陶城报官网陶城网-陶瓷卫浴技术",
                }
            },
            {
                path: '/recruiter',
                component: () => import('@/views/recruiter'),
                meta: {
                    title: "陶城报官网陶城网-陶瓷卫浴招聘",
                }
            },
            // {
            //     path: '/video',
            //     component: () => import('@/views/video'),
            //     meta: {
            //         title: "CTW-视频",
            //     }
            // },
            // {
            //     path: '/quote',
            //     component: () => import('@/views/quote'),
            //     meta: {
            //         title: "CTW-市场",
            //     }
            // },
            // {
            //     path: '/sell',
            //     component: () => import('@/views/sell'),
            //     meta: {
            //         title: "CTW-技术/供应商",
            //     }
            // },
            // {
            //     path: '/exhibit',
            //     component: () => import('@/views/exhibit'),
            //     meta: {
            //         title: "CTW-展会",
            //     }
            // },
            // {
            //     path: '/rank',
            //     component: () => import('@/views/rank'),
            //     meta: {
            //         title: "CTW-百强",
            //     }
            // },
            {
                path: '/search',
                component: () => import('@/views/search'),
                meta: {
                    title: "陶城报官网陶城网-搜索",
                }
            },
            {
                path: '*',
                component: () => import('@/views/index'),
                meta: {
                    title: '404'
                }
            }
        ],
    },
    {
        path: '/login',
        component: () => import('@/views/login'),
        meta: {
            title: "陶城报官网陶城网-登录",
        }
    },
    {
        path: '/me',
        component: () => import('@/views/me'),
        meta: {
            title: "陶城报官网陶城网-个人中心",
            needLogin:true
        }
    },
    {
        path: '/register',
        component: () => import('@/views/me/register.jsx'),
        meta: {
            title: "陶城报官网陶城网-注册",
            needLogin:false
        }
    },
    {
        path: '/base',
        component: () => import('@/views/me/base.jsx'),
        meta: {
            title: "陶城网-编辑个人信息",
            needLogin:false
        }
    },
    {
        path: '/show/:table/:id',
        component: () => import('@/views/show'),
        meta: {
            title: "陶城报官网陶城网",
            needLogin:false
        }
    }
]

export default routes;