import './Search.css';
import React,{useState,useCallback,useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import search from '@/assets/search.svg';


/*基础弹出层 */
export default function Search(props){
    const navigate = useNavigate();
	
    const [keyword,setKeyword] = useState('');
    const inputChange = useCallback((e)=>{
        setKeyword(e.target.value)
    },[])

    const onSearch = useCallback(()=>{
        console.log(keyword)
		navigate('/search?key='+keyword,{replace:true})
		// dispatch(incrementByAmount(10))
	},[keyword])

    const onSub = (event)=>{
        event.preventDefault()
    }

    // useEffect(()=>{
    //     document.getElementById("searchBox").addEventListener('keydown',onKeyDown);
    //     return ()=>{
    //         document.getElementById("searchBox").removeEventListener('keydown',onKeyDown);
    //     }
    // },[])
    // const onKeyDown = useCallback((e,keyword)=>{
    //     switch(e.keyCode){
    //         case 13:
    //             // onSearch()
    //             break;
    //     }
    // },[keyword])


	return (
        <form className='search-box' id="searchBox" onSubmit={(e)=>onSub(e)}>
            <input type='text' onChange={inputChange} value={keyword} />
            <span className={keyword===''?'clearSearch':'clearSearch show'} onClick={()=>setKeyword('')}></span>
            <button type="submit" onClick={onSearch}></button>
            {/* <button type="submit" className='svgbox' style={{position: "relative", width: "16px",height: "16px", overflow: "hidden"}} onClick={onSearch}>
                <img src={search} alt='' style={{width:"16px",height:"16px", position:"absolute", left: "-20px", filter: "drop-shadow(20px 0 #25323a)" }} />
            </button> */}
		</form>
	);
};