import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getExpoAlbum} from '@/api/index.js'

const initialState = {
  album:[]
}

export const getAlbum = createAsyncThunk('exhibit/getExpoAlbum', async (params={}) => {
  const payload = await getExpoAlbum(params)
  return payload
})

export const exhibitSlice = createSlice({
  name: 'exhibit',
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder.addCase(getAlbum.fulfilled, (state, { payload }) => {
      console.log(payload)
      payload.errcode===200&&(state.album = payload.data);
      // state.total = payload.total;
    })
  }
})

// Action creators are generated for each case reducer function
// export const { getAlbum } = exhibitSlice.actions

export default exhibitSlice.reducer