import React,{useEffect} from 'react';
import { useRoutes, useNavigate, Navigate } from 'react-router-dom';
import routes from './routes';
import { Spin } from 'antd';
import {store} from '../stores/index.js';
// import {useCheckLogin} from '@/use/diy-use.js';




export default function Routes() {
    const element = useRoutes(renderRoutes(routes));
    return element;
}

function renderRoutes(routes) {
    // console.log(routes)
    return routes.map((item)=>{
        // console.log(item)
        let res = { ...item };
        if (!item?.path) return;

        // component
        if (item?.component) {
            const Component = React.lazy(item.component);
            // console.log(item.component)
            // res.element = (<React.Suspense fallback={<Spin size="large" />}>
            res.element = (<React.Suspense>
                <BeforeEach route={item}>
                    <Component />
                </BeforeEach>
            </React.Suspense>);
        }

        // 子路由
        if (item?.children) {
            res.children = renderRoutes(item.children);
        }

        // 重定向
        if (item?.redirect) {
            res.element = (
                <Navigate to={item.redirect} replace />
            )
        }

        return res;
    })
}

//劫持路由
function BeforeEach(props) {
    const navigate = useNavigate();
    
    if (props?.route?.meta?.title) {
        document.title = props.route.meta.title;
    }
    useEffect(()=>{
        if(props?.route?.meta?.needLogin){
            console.log("判断是否有登录")
            // let user = store.getState().reducerUser.user;
            let user = JSON.parse(localStorage.getItem('user')||'{}');

            // if(!user.member_info||!user.member_info.name){
            if(!user?.email){
                console.log("未登录");
                localStorage.clear();
                navigate("/index",{replace:true});
            }else{
                console.log("已登录")
            }
        }
    },[])

    return <div>
        {props.children}
    </div>
}