import { useLocation,useNavigate } from "react-router-dom";
import React, { useState,useEffect,useRef } from 'react';
// import store from '../store/index.js';
// import wx from 'weixin-jsapi'
import axios from 'axios';
const wx = window.wx;

// console.log(wx)

export function getQueryVariable(variable){
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
		if(pair[0] === variable){return pair[1];}
	}
	return(false);
}

export function useGetDevice(){
	let type = null;
	if(document.body.clientWidth>800){
		type = 'pc';
	}else{
		type = 'mobile';
	}
	return type;
}

export function useGetDeviceType(){
	let u = navigator.userAgent;
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
		return "android";
    }
    if (isIOS) {
  return "ios";
    }
	return "pc";
}

export function useScrollToParamsId(){
	const loc = useLocation();
	
	useEffect(() => {
   if(loc.state && loc.state.id){
            let elemTop = document.getElementById(loc.state.id).offsetTop;
            document.documentElement.scrollTop = elemTop;
            document.body.scrollTop = elemTop;
         }
     })
}

export function useScrollToTop(){
	useEffect(() => {
		// console.log('effect useScrollToTop');
		document.documentElement.scrollTop = 0;
		document.body.scrollTop = 0;
	},[])
}

// export function useCheckLogin(){
// 	const history = useNavigate();
// 	let user = store.getState().reducerUser.user;
// 	console.log(user)
// 	useEffect(() => {
// 		if(!user.member_info||!user.member_info.name){
// 			console.log("未登录");
// 			const source = localStorage.getItem("source2024");
// 			localStorage.clear();
// 			!!source&&localStorage.setItem("source2024",source);
// 			history("/login",{replace:true});
// 		}
// 	},[user])
// }

export function usePageAnimate(){
	const [classname,setClassname] = useState("page-animate");
	const history = useNavigate();
	const sto1 = useRef(null);
	const sto2 = useRef(null);

	
	useEffect(() => {
		console.log("effect 加载");
		sto1.current = setTimeout(()=>{setClassname("page-animate show");},0)
		
		return ()=>{
			setClassname("page-animate");
			clearTimeout(sto1.current);
			clearTimeout(sto2.current);
			console.log("effect 清除");
		}
	},[])

	const pageBackFn = ()=>{
		setClassname("page-animate");
		sto2.current = setTimeout(()=>{history.goBack();},200)
	}

	return {
		pageAnimateClassName:classname,
		pageBackFn:pageBackFn
	}
}


// export function ExitLogin(){
// 	const history = useNavigate();
// 	const source = localStorage.getItem("source");
// 	store.dispatch({ type: "set_user", user:{}});
// 	localStorage.clear();
// 	localStorage.setItem("source2024",source);
// 	history.replace({pathname:"/login"});
// }

// export function ExitLogin(){
// 	const history = useNavigate();
// 	const source = localStorage.getItem("source");
// 	store.dispatch({ type: "set_user", user:{}});
// 	localStorage.clear();
// 	localStorage.setItem("source2024",source);
// 	history.replace({pathname:"/login"});
// }

export const useWindowSize = () => {
	const [size, setSize] = React.useState({ width: window.innerWidth, height: window.innerHeight });
   
	useEffect(() => {
	  const handleResize = () => {
		setSize({ width: window.innerWidth, height: window.innerHeight });
	  };
	  
	  window.addEventListener("resize", handleResize);
	  return () => {
		window.removeEventListener("resize", handleResize);
	  };
	}, []);
   
	return size;
  };


  export const useWindowScroll = () => {
	const [top, setTop] = React.useState(0);
   
	useEffect(() => {
	  const handleScroll = () => {
		// console.log(document.body.scrollTop,window.scrollY,window.pageYOffset)
		setTop(window.scrollY);
	  };
	  
	  window.addEventListener("scroll", handleScroll);
	  return () => {
		window.removeEventListener("scroll", handleScroll);
	  };
	}, []);
   
	return top;
  };


  export const scrollToTop = ()=>{
	var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
	console.log('scorllTop')
    if (currentScroll > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, currentScroll - (currentScroll / 5));
    }
  }



  export function setWeixinShare(tTitle="陶城网-陶城报官方网站陶瓷行业门户全球陶瓷资讯平台", tContent="全球陶瓷行业新闻品牌新品动态，陶瓷卫浴100强企业，陶瓷品牌招商加盟，陶瓷装备材料发展",diyUrl=""){

	let shareLink = "https://www.fstcb.com";
	
	if(diyUrl!==""){
		shareLink = diyUrl;
	}

    window.shareData = {
      "imgUrl": "https://www.fstcb.com/images/wx_share.jpg",
      "timeLineLink": shareLink,
      "tTitle": tTitle,
      "tContent": tContent
    };

	var url=encodeURIComponent(window.location.href.split('#')[0]);

	axios.get("https://admtcb24.fstcb.com/wxsdk/jssdk.php?url="+url,{}).then(response=>{
		const res = response.data;
		// console.log(res);
		wx.config({
			debug: false,
			appId: res.appId,
			timestamp: res.timestamp,
			nonceStr: res.nonceStr,
			signature: res.signature,
			jsApiList: ['updateTimelineShareData','updateAppMessageShareData']
		});
	})

	wx.ready(function () {
        //分享到朋友圈
        wx.updateTimelineShareData({
            title: window.shareData.tTitle, // 分享标题
            link: window.shareData.timeLineLink, // 分享链接
            imgUrl: window.shareData.imgUrl
        });
        //发送给好友
        wx.updateAppMessageShareData({
            title: window.shareData.tTitle, // 分享标题
            desc: window.shareData.tContent, // 分享描述
            link: window.shareData.timeLineLink, // 分享链接
            imgUrl: window.shareData.imgUrl, // 分享图标
        });
        wx.error(function(res){
			console.log(res);
        })
    });
}
