import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom';
import{ default as ConfigRoutes} from '@/router/index.jsx';
import DHeader from '@/components/DHeader';
import DFooter from '@/components/DFooter';
import GoUp from '@/components/GoUp';
import LoginBox from '@/components/LoginBox';


import { ConfigProvider } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';


// import App from './App.jsx'
// import 'antd/dist/antd.css';
// import reportWebVitals from './reportWebVitals';

import { store } from '@/stores/'
import { Provider } from 'react-redux'

import './styles/common.css';

setTimeout(()=>{
  document.dispatchEvent(new Event('custom-render-trigger'))
},2000)

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider theme={{ token: { colorPrimary: '#c71b29' }}}>
      <StyleProvider hashPriority="high">
        <BrowserRouter>
          <DHeader></DHeader>
          <ConfigRoutes />
          <DFooter></DFooter>
          <GoUp></GoUp>
          <LoginBox></LoginBox>
        </BrowserRouter>
      </StyleProvider>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
)

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
